import React, { useMemo } from 'react';
import { useController, Control, FieldValues, Path } from 'react-hook-form';
import { Form, Icon } from 'react-bulma-components';
import { Color } from 'react-bulma-components/src/components';

export interface TextInputProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  color?: Color;
  leftIcon?: string;
  error?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
}

export function TextInput<TFieldValues extends FieldValues>({
  control,
  name,
  type,
  placeholder,
  color,
  leftIcon,
  error,
  fullWidth = false,
  disabled = false,
  required = true, // HACK: This is inverted default!
}: TextInputProps<TFieldValues>) {
  const {
    field: { ref, ...field },
    fieldState: { error: controllerError, invalid },
  } = useController({
    control,
    name,
    rules: {
      required: {
        value: required, // TODO: Should we short-circuit it if it's disabled?
        message: 'Pole nie moze być puste',
      },
      minLength: {
        value: 2,
        message: 'Pole musi zawierać conajmniej 2 litery',
      },
    },
  });
  const errorMsg = error || controllerError?.message;

  const memoizedLeftIcon = useMemo(
    () =>
      leftIcon && (
        <Icon size="small" align="left">
          <i className={`fa ${leftIcon}`} />
        </Icon>
      ),
    [leftIcon],
  );

  return (
    <Form.Field>
      <Form.Control fullwidth={fullWidth}>
        {memoizedLeftIcon}
        <Form.Input
          type={type}
          placeholder={placeholder}
          color={invalid ? 'danger' : color}
          disabled={disabled}
          {...field}
          domRef={ref}
        />
      </Form.Control>
      {errorMsg && <Form.Help color="danger">{errorMsg}</Form.Help>}
    </Form.Field>
  );
}
