import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import { Box, Container, Columns, Tile, Heading } from 'react-bulma-components';
import { useCart } from './cart/hook';
import { TripSearchForm } from './trip_search/TripSearchForm';
import { TripEntry } from './trip_search/TripEntry';
import { useTripSearchParams } from './trip_search/hooks';
import {
  groupBySections,
  makePassengerCountsParams,
  toPassengerCounts,
} from './trip_search/utils';
import { CartItem } from './cart/api';
import { TripOfferDTO, useTripOffers } from './api/trips/api';
import { useBusStations } from './api/stations/hooks';
import { CartListing } from './cart/CartListing';

// What should happen when user presses "Wybierz"?
// 1. add product to virtual cart
//    - if cart does not exist:
//       1. create a new empty cart
// 2. update the cart
// 3. navigate to next screen / subpage
// 4. scroll to the top (if needed)

function App() {
  useBusStations();
  const { addItem } = useCart();
  const navigate = useNavigate();
  const { mergedValues, isValid, submitValues } = useTripSearchParams();

  const passengerCountsParams = makePassengerCountsParams(
    (param) => mergedValues[param],
  );

  const { data: foundTrips, isLoading } = useTripOffers(mergedValues, isValid);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onTripSelected = useCallback(
    (trip: TripOfferDTO) => {
      addItem({
        tripId: trip.tripId,
        passengersCounts: toPassengerCounts(passengerCountsParams),
        price: trip.minPrice,
      } as CartItem);

      // TODO: If we have to find a return trip, do so (redirect)
      const isRoundTrip = mergedValues.tripType === 'roundTrip';
      const isReturnTripSearch = mergedValues.direction === 'back';
      console.log(
        `DEBUG: isRoundTrip=${isRoundTrip}(=${mergedValues.tripType}) isReturnTripSearch=${isReturnTripSearch}(=${mergedValues.direction})`,
      );
      if (isRoundTrip && !isReturnTripSearch) {
        // Navigate to return trip search
        submitValues({ direction: 'back' });
      } else {
        // We can simply navigate to checkout
        console.log('TODO: Navigate to checkout page');
        navigate('/order/checkout', { state: 'checkout' });
      }
    },
    [mergedValues, addItem],
  );

  let searchResults;
  if (isLoading || foundTrips === undefined) {
    if (isValid) {
      searchResults = <>Loading...</>;
    } else {
      // Most probably the user has not pressed the "search"
      searchResults = <></>;
    }
  } else {
    // Group found trips by departure date, so we can display them in a "sectioned list"-like fashion
    const dateFormatter = Intl.DateTimeFormat(undefined, {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
    const groupedTrips = groupBySections(foundTrips, (item) =>
      dateFormatter.format(new Date(item.departureTime)),
    );
    searchResults = Object.entries(groupedTrips).map(([date, trips]) => {
      return (
        <Fragment key={date}>
          <h3 className="title">{date}</h3>
          {trips.map((trip: TripOfferDTO) => (
            <TripEntry
              key={trip.tripId}
              tripId={trip.tripId}
              onSelectPress={() => onTripSelected(trip)}
            />
          ))}
        </Fragment>
      );
    });
  }

  return (
    <>
      <Box>
        <Container>
          <TripSearchForm
            defaultValues={mergedValues}
            onSubmit={submitValues}
          />
        </Container>
      </Box>

      <Container>
        {/* Search results */}
        <Columns>
          <Columns.Column tablet={{ size: 'two-thirds' }}>
            {/* TODO: Show a big header, like "Wybierz trasę docelową" or "Wybierz przejazd powrotny" */}
            {searchResults}
          </Columns.Column>

          {/* Sidebar */}
          <Columns.Column tablet={{ size: 'one-third' }}>
            <Tile kind="ancestor">
              <Tile size={12} kind="parent" vertical>
                <Tile kind="child">
                  <Box>
                    {/* TODO: We can display here a box encouriging to login / signup */}
                    <Heading>Zaloguj / Zarejestruj</Heading>
                  </Box>
                </Tile>

                <Tile kind="child">
                  <Box>
                    <CartListing withSummary />
                  </Box>
                </Tile>
              </Tile>
            </Tile>
          </Columns.Column>
        </Columns>
      </Container>
    </>
  );
}

export default App;
