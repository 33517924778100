import { ReactNode } from 'react';
import { Timeline, TimelineItem } from '../components/Timeline';
import { Element } from 'react-bulma-components'; // eslint-disable-line no-redeclare

interface TripLineStopProps {
  time?: string;
  boldTime?: boolean;
  content: ReactNode | string;
  outlined?: boolean;
  last?: boolean;
  compact?: boolean;
}

export function TripLineStop({
  time,
  content,
  boldTime = false,
  outlined = false,
  last = false,
  compact = false,
}: TripLineStopProps) {
  return (
    <TimelineItem
      contentBefore={
        boldTime ? (
          <Element textSize={5} textWeight="bold">
            {time}
          </Element>
        ) : (
          time
        )
      }
      contentAfter={content}
      outlined={outlined}
      compact={compact}
      noConnector={last}
    />
  );
}

interface TripLineProps {
  children: ReactNode;
  align?: 'left' | 'center' | 'right';
  paddingless?: boolean;
}

export function TripLine({
  children,
  align = 'center',
  paddingless = false,
}: TripLineProps) {
  return (
    <Timeline align={align} paddingless={paddingless}>
      {children}
    </Timeline>
  );
}
