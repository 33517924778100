import { useEffect } from 'react';
import {
  QueryKey,
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

function overwriteMerger<TItem>(_oldItem: TItem | undefined, newItem: TItem) {
  return newItem;
}

export interface UseListQueryOptions<
  TQueryFnDataItem = unknown,
  TError = unknown,
  TData = TQueryFnDataItem[],
  TQueryKey extends QueryKey = QueryKey,
> extends UseQueryOptions<TQueryFnDataItem[], TError, TData, TQueryKey> {
  keyExtractor: (item: TQueryFnDataItem, index: number) => unknown;
  queryKeyExtractor: (key: unknown, listQueryKey: TQueryKey) => TQueryKey;
  merger?: (
    oldItem: TQueryFnDataItem | undefined,
    newItem: TQueryFnDataItem,
  ) => TQueryFnDataItem;
}

export function useListQuery<
  TQueryFnDataItem = unknown,
  TError = unknown,
  TData extends TQueryFnDataItem[] = TQueryFnDataItem[],
  TQueryKey extends QueryKey = QueryKey,
>({
  keyExtractor,
  queryKeyExtractor,
  merger = overwriteMerger,
  ...queryOptions
}: UseListQueryOptions<TQueryFnDataItem, TError, TData, TQueryKey>) {
  const queryClient = useQueryClient();
  const queryApi = useQuery<TQueryFnDataItem[], TError, TData, TQueryKey>(
    queryOptions,
  );

  // Emulate "onSuccess", when the query successfully fetched new data
  const { data, isSuccess } = queryApi;
  const listQueryKey = (queryOptions.queryKey ?? []) as TQueryKey;
  useEffect(() => {
    if (isSuccess) {
      data?.forEach((item, index) => {
        const detailKey = keyExtractor(item, index);
        const detailQueryKey = queryKeyExtractor(detailKey, listQueryKey);
        queryClient.setQueryData<TQueryFnDataItem>(detailQueryKey, (oldItem) =>
          merger(oldItem, item),
        );
        queryClient.setQueryDefaults(detailQueryKey, { staleTime: Infinity });
      });
    }
  }, [isSuccess, data, queryClient]);

  return queryApi;
}
