import React, { useCallback } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { Form } from 'react-bulma-components';

export interface RadioButtonProps {
  value: string;
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export function RadioButton({
  value,
  label,
  checked = false,
  disabled = false,
  onChange,
}: RadioButtonProps) {
  return (
    <Form.Radio
      // {...field}
      value={value}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    >
      {label}
    </Form.Radio>
  );
}

export interface RadioButtonGroupProps<TFieldValues extends FieldValues> {
  control?: Control<TFieldValues>;
  name: Path<TFieldValues>;
  fullWidth?: boolean;
  children: React.ReactNode;
  onValueChange?: (value: string) => void;
}

export function RadioButtonGroup<TFieldValues extends FieldValues>({
  control,
  name,
  fullWidth = false,
  children,
  onValueChange,
}: RadioButtonGroupProps<TFieldValues>) {
  const {
    field: { value, onChange /*ref, ...field*/ },
    // fieldState: { error, invalid },
  } = useController({
    control,
    name,
    rules: {
      required: {
        value: true,
        message: 'To pole jest wymagane',
      },
    },
  });

  const onSelect: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const newValue = event.target.value;
      onValueChange?.(newValue);
      onChange(newValue);
    },
    [onValueChange, onChange],
  );

  const childrenArray = React.Children.map(children, (child /*index*/) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(
        child,
        child.type === RadioButton
          ? ({
              // overwrite `onChange` with our handler
              onChange: onSelect,
              // overwrite the `checked` prop
              checked: child.props.value === value,
            } as Partial<unknown>)
          : {},
      );
    } else {
      return child;
    }
  });

  return (
    <Form.Field>
      <Form.Control fullwidth={fullWidth}>
        {childrenArray}
        {/* <Form.Radio
          {...field}
          value='single'
          checked={value !== 'roundTrip'}
          onChange={(e) => onChange(e.target.value)}
        >
          W jedną stronę
        </Form.Radio> */}
      </Form.Control>
    </Form.Field>
  );
}
