import {
  Columns,
  Dropdown,
  Element, // eslint-disable-line no-redeclare
  Form,
} from 'react-bulma-components';
import { IconButton } from '../components/IconButton';

export interface PassengerCounterProps {
  title: string;
  description?: string;
  name: string;
  value: number;
  onChange: (value: number) => void;
  onBlur: () => void;
}

export function PassengerCounter({
  title,
  description,
  name,
  value,
  onChange,
  onBlur,
}: PassengerCounterProps) {
  /* FIXME: The value field below is not needed, and its rendered wrongly as `title` attribute... */
  return (
    <Dropdown.Item value={title}>
      <Columns style={{ minWidth: 300 }}>
        <Columns.Column size="two-fifths">
          <Element textWeight="bold">{title}</Element>
          <Element>{description}</Element>
        </Columns.Column>
        <Columns.Column size="three-fifths" display="flex" flexDirection="row">
          <IconButton
            disabled={value <= 0}
            onClick={() => onChange(value - 1)}
            iconSize="small"
            icon="fa fa-minus"
          />
          <Form.Input
            type="number"
            name={name}
            value={value}
            onChange={(e) => onChange(parseInt(e.target.value, 10))}
            onBlur={onBlur}
          />
          <IconButton
            onClick={() => onChange(value + 1)}
            iconSize="small"
            icon="fa fa-plus"
          />
        </Columns.Column>
      </Columns>
    </Dropdown.Item>
  );
}
