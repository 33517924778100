import { Form as RouterForm } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Columns, Dropdown, Form, Icon, Button } from 'react-bulma-components';
import { addDays, formatCount } from '../utils';
import { TripSearchInputs } from '../api/trips/api';
import { TextInput } from '../components/TextInput';
import { DatePicker } from '../components/DatePicker';
import { RadioButton, RadioButtonGroup } from '../components/RadioButton';
import { PassengerCounter } from './PassengerCounter';
import { countTotalPassengers, makePassengerCountsParams } from './utils';

export interface TripSearchFormProps {
  defaultValues: TripSearchInputs;
  onSubmit: SubmitHandler<Partial<TripSearchInputs>>;
}

export function TripSearchForm({
  defaultValues,
  onSubmit,
}: TripSearchFormProps) {
  const { control, watch, handleSubmit } = useForm<TripSearchInputs>({
    defaultValues,
  });

  const tomorrow = addDays(1);
  const isRoundTrip = watch('tripType') === 'roundTrip';
  const passengerCounts = makePassengerCountsParams(watch, defaultValues);
  const totalPassengers = countTotalPassengers(passengerCounts);

  return (
    <RouterForm method="get" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="direction"
        rules={{ required: isRoundTrip }}
        render={({ field: { ref, ...field } }) => (
          <Form.Input type="hidden" {...field} domRef={ref} hidden={true} />
        )}
      />
      <RadioButtonGroup control={control} name="tripType">
        <RadioButton value="single" label="W jedną stronę" />
        <RadioButton value="roundTrip" label="W obie strony" />
      </RadioButtonGroup>

      <Columns multiline>
        {/* First column is for bus stations selection */}
        <Columns.Column
          widescreen={{ size: 'two-fifths' }}
          tablet={{ size: /*'full'*/ 12 }}
          mobile={{ size: /*'full'*/ 12 }}
        >
          <Columns>
            <Columns.Column
              tablet={{ size: 'half' }}
              mobile={{ size: /*'full'*/ 12 }}
            >
              {/* Departure station */}
              <TextInput
                control={control}
                name="from"
                placeholder={'Skąd jedziemy?'}
                leftIcon={'fa-map-marker'}
              />
            </Columns.Column>

            <Columns.Column
              tablet={{ size: 'half' }}
              mobile={{ size: /*'full'*/ 12 }}
            >
              {/* Destination station */}
              <TextInput
                control={control}
                name="to"
                placeholder="Dokąd jedziemy"
                leftIcon="fa-flag-checkered"
              />
            </Columns.Column>
          </Columns>
        </Columns.Column>

        {/* Second column is for dates and passengers */}
        <Columns.Column
          widescreen={{ size: 'three-fifths' }}
          tablet={{ size: /*'full'*/ 12 }}
          mobile={{ size: /*'full'*/ 12 }}
        >
          <Columns multiline>
            {/* This column is for date pickers */}
            <Columns.Column
              widescreen={{ size: 'three-fifths' }}
              desktop={{ size: 'two-fifths' }}
              tablet={{ size: 'two-thirds' }}
            >
              <Form.Field kind="addons">
                {/* NOTE: By default we pick tomorrow's date (now + 24h) */}
                <DatePicker
                  control={control}
                  name="departureDate"
                  defaultValue={defaultValues.departureDate}
                  minValue={tomorrow}
                  fullWidth
                />
                <DatePicker
                  control={control}
                  name="returnDate"
                  defaultValue={
                    defaultValues.returnDate ?? defaultValues.departureDate
                  }
                  minValue={tomorrow}
                  disabled={!isRoundTrip}
                  fullWidth
                />
              </Form.Field>
            </Columns.Column>

            {/* This column is for passengers dropdown */}
            <Columns.Column
              widescreen={{ size: 'one-fifth' }}
              desktop={{ size: 'two-fifths' }}
              tablet={{ size: 'one-third' }}
            >
              <Form.Field>
                <Form.Control fullwidth>
                  {/* FIXME: The `fullwidth` prop is not working on the dropdown... */}
                  <Dropdown
                    closeOnSelect={false}
                    label={formatCount(totalPassengers, 'pasażer', 'pasażerów')}
                    icon={
                      <Icon size="small" align="left">
                        <i className="fa fa-angle-down" />
                      </Icon>
                    }
                    className="is-fullwidth"
                    fullwidth
                    right
                  >
                    <Controller
                      name="adults"
                      control={control}
                      render={({ field: { ...field } }) => (
                        <PassengerCounter
                          title="Dorośli"
                          description="Powyżej 18 lat"
                          {...field}
                          // domRef={ref}
                        />
                      )}
                    />
                    <Controller
                      name="students"
                      control={control}
                      render={({ field: { ...field } }) => (
                        <PassengerCounter
                          title="Studenci"
                          description="z legitymacją"
                          {...field}
                          // domRef={ref}
                        />
                      )}
                    />
                    <Controller
                      name="adolescents"
                      control={control}
                      render={({ field: { ...field } }) => (
                        <PassengerCounter
                          title="Młodzież"
                          description="od 6 do 18 lat"
                          {...field}
                          // domRef={ref}
                        />
                      )}
                    />
                    <Controller
                      name="children"
                      control={control}
                      render={({ field: { ...field } }) => (
                        <PassengerCounter
                          title="Dzieci"
                          description="od 1 do 6 lat"
                          {...field}
                          // domRef={ref}
                        />
                      )}
                    />
                    <Controller
                      name="infants"
                      control={control}
                      render={({ field: { ...field } }) => (
                        <PassengerCounter
                          title="Niemowlęta"
                          description="do 12 miesięcy"
                          {...field}
                          // domRef={ref}
                        />
                      )}
                    />
                    <Dropdown.Divider />
                    <Controller
                      name="pets"
                      control={control}
                      render={({ field: { ...field } }) => (
                        <PassengerCounter
                          title="Zwierzęta"
                          description="Małe lub średnie"
                          {...field}
                          // domRef={ref}
                        />
                      )}
                    />
                    <Controller
                      name="pets"
                      control={control}
                      render={({ field: { ...field } }) => (
                        <PassengerCounter
                          title="Przesyłka"
                          description="konduktorska"
                          {...field}
                          // domRef={ref}
                        />
                      )}
                    />
                  </Dropdown>
                </Form.Control>
              </Form.Field>
            </Columns.Column>

            {/* Column with the search button */}
            <Columns.Column
              desktop={{ size: 'one-fifth' }}
              tablet={{ size: /*'full'*/ 12 }}
            >
              <Button
                color="info"
                disabled={totalPassengers === 0}
                fullwidth
                submit
                /*rounded*/
              >
                Wyszukaj
              </Button>
            </Columns.Column>
          </Columns>
        </Columns.Column>
      </Columns>
    </RouterForm>
  );
}
