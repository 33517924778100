import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { addDays, tryParseDate, tryParseDecimal } from '../utils';
import {
  defaultPassengerCountParams,
  makePartialPassengerCountsParams,
} from './utils';
import { TripSearchInputs } from '../api/trips/api';

type TripSearchInputsKeys = Array<keyof TripSearchInputs>;

export const defaultTripSearchInputs: TripSearchInputs = {
  direction: 'there',
  tripType: 'single',
  from: 'Szczecin',
  to: 'Berlin',
  departureDate: addDays(1),
  returnDate: addDays(2),
  ...defaultPassengerCountParams,
};

function gatherTripSearchParams(
  searchParams: URLSearchParams,
): Partial<TripSearchInputs> {
  const passengerCountsParams = makePartialPassengerCountsParams((param) =>
    tryParseDecimal(searchParams.get(param)),
  );

  // Gather all search query parameters into a struct
  const tripSearchParams: Partial<TripSearchInputs> = {
    direction: (searchParams.get('direction') || undefined) as
      | TripSearchInputs['direction']
      | undefined,
    tripType: (searchParams.get('tripType') || undefined) as
      | TripSearchInputs['tripType']
      | undefined,
    from: searchParams.get('from') || undefined,
    to: searchParams.get('to') || undefined,
    departureDate: tryParseDate(searchParams.get('departureDate')),
    returnDate: tryParseDate(searchParams.get('returnDate')),
    ...passengerCountsParams,
  };

  // Remove all `undefined` fields from `tripSearchParams`,
  // which is extremely handy when "spreading" into an object.
  (Object.keys(tripSearchParams) as TripSearchInputsKeys).forEach(
    (k) => tripSearchParams[k] === undefined && delete tripSearchParams[k],
  );

  return tripSearchParams;
}

export interface TripSearchParamsApi {
  paramValues: Partial<TripSearchInputs>;
  mergedValues: TripSearchInputs;
  isValid: boolean;
  submitValues: (data: Partial<TripSearchInputs>) => void;
}

// NOTE: This hook tries to keep in sync the `TripSearchInputs` with SearchParams (both ways?)
export function useTripSearchParams(
  defaultValues: TripSearchInputs = defaultTripSearchInputs,
): TripSearchParamsApi {
  const [searchParams, setSearchParams] = useSearchParams();

  const tripSearchParams = gatherTripSearchParams(searchParams);

  // Check, if we have all required params submitted
  const submittedParams = new Set(
    Object.keys(tripSearchParams) as TripSearchInputsKeys,
  );
  const mainPassengerCount = (
    ['adults', 'students', 'adolescents', 'packages'] as TripSearchInputsKeys
  )
    .map((k) => (tripSearchParams?.[k] as number) ?? 0)
    .reduce((accum, n) => accum + n, 0);
  const hasRequiredParams =
    (
      [
        'direction',
        'tripType',
        'from',
        'to',
        'departureDate',
      ] as TripSearchInputsKeys
    ).every((k) => submittedParams.has(k)) &&
    (tripSearchParams['tripType'] === 'roundTrip'
      ? submittedParams.has('returnDate')
      : true);

  // Apply them on top of default values
  const mergedSearchParams: TripSearchInputs = {
    ...defaultValues,
    ...tripSearchParams,
  };

  const handleSubmit = useCallback(
    (data: Partial<TripSearchInputs>) => {
      const mergedParams: TripSearchInputs = {
        ...defaultValues,
        ...data,
      };
      Object.entries(mergedParams).forEach(([key, value]) => {
        if (value !== undefined) {
          if (value instanceof Date) {
            // NOTE: This branch will also be taken on invalid `Date` objects, like `Date("foo")`
            searchParams.set(key, value.toISOString().slice(0, 10));
          } else {
            searchParams.set(key, value.toString());
          }
        } else {
          if (searchParams.has(key)) {
            searchParams.delete(value);
          }
        }
      });
      const now = Date.now();
      setSearchParams(searchParams, { preventScrollReset: true, state: now });
    },
    [defaultValues, searchParams, setSearchParams],
  );

  return {
    paramValues: tripSearchParams,
    mergedValues: mergedSearchParams,
    isValid: hasRequiredParams && mainPassengerCount > 0,
    submitValues: handleSubmit,
  };
}
