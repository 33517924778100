import { ReactNode, useMemo } from 'react';
import MuiTimeline from '@mui/lab/Timeline';
import MuiTimelineItem from '@mui/lab/TimelineItem';
import MuiTimelineDot from '@mui/lab/TimelineDot';
import MuiTimelineSeparator from '@mui/lab/TimelineSeparator';
import MuiTimelineConnector from '@mui/lab/TimelineConnector';
import MuiTimelineContent, {
  timelineContentClasses,
} from '@mui/lab/TimelineContent';
import MuiTimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

type MuiSxProps = SxProps<Theme>;

const emptySx: MuiSxProps = {};

const emptyContentHack: MuiSxProps = {
  padding: 0,
  maxWidth: 0,
};

interface TimelineItemProps {
  contentBefore?: ReactNode | string;
  contentAfter?: ReactNode | string;
  outlined?: boolean;
  compact?: boolean;
  noConnector?: boolean;
}

export function TimelineItem({
  contentBefore,
  contentAfter,
  outlined = false,
  compact = false,
  noConnector = false,
}: TimelineItemProps) {
  const itemSx = compact ? { minHeight: 50 } : undefined; // default: 70
  // HACK: The dotSx style will not work when there are more than 2 TimelineItems!
  const dotSx = compact
    ? noConnector
      ? { marginTop: '10px' }
      : { marginBottom: '10px' }
    : undefined; // default; 11.5
  const contentSx = compact ? { padding: '6px' } : undefined;

  const oppositeContent = useMemo(() => {
    // HACK: We MUST provide the `MuiTimelineOppositeContent` even if we are not using it!
    return contentBefore === undefined ? (
      <MuiTimelineOppositeContent sx={emptyContentHack} />
    ) : (
      <MuiTimelineOppositeContent sx={contentSx}>
        {contentBefore}
      </MuiTimelineOppositeContent>
    );
  }, [contentBefore, compact]);

  const content = useMemo(() => {
    // HACK: We MUST provide the `MuiTimelineContent` even if we are not using it!
    return contentAfter === undefined ? (
      <MuiTimelineContent sx={emptyContentHack} />
    ) : (
      <MuiTimelineContent sx={contentSx}>{contentAfter}</MuiTimelineContent>
    );
  }, [contentAfter, compact]);

  return (
    <MuiTimelineItem sx={itemSx}>
      {oppositeContent}
      <MuiTimelineSeparator>
        <MuiTimelineDot variant={outlined ? 'outlined' : 'filled'} sx={dotSx} />
        {noConnector ? undefined : <MuiTimelineConnector />}
      </MuiTimelineSeparator>
      {content}
    </MuiTimelineItem>
  );
}

type TimelineAlign = 'left' | 'center' | 'right';

interface TimelineProps {
  children: ReactNode;
  align?: TimelineAlign;
  paddingless?: boolean;
}

function getMuiTimelineAlignStyle(
  align: TimelineAlign,
  flex: number,
): MuiSxProps {
  if (align === 'left') {
    return { [`& .${timelineOppositeContentClasses.root}`]: { flex } };
  } else if (align === 'right') {
    return { [`& .${timelineContentClasses.root}`]: { flex } };
  } else {
    return emptySx;
  }
}

export function Timeline({
  children,
  align = 'center',
  paddingless = false,
}: TimelineProps) {
  const style = useMemo(() => {
    let styles = getMuiTimelineAlignStyle(align, 0.1);

    if (paddingless) {
      styles = { ...styles, padding: 0 };
    }

    return styles;
  }, [align, paddingless]);

  return (
    <MuiTimeline position="right" sx={style}>
      {children}
    </MuiTimeline>
  );
}
