import {
  Box,
  Card,
  Columns,
  Element, // eslint-disable-line no-redeclare
  Button,
} from 'react-bulma-components';
import { formatCount, formatHourMinutes, formatPrice } from '../utils';
import { useTripOffer } from '../api/trips/api';
import { useBusStation } from '../api/stations/hooks';
import { TripLine, TripLineStop } from './TripLine';
import { StationContent } from './StationContent';
import { useCart } from '../cart/hook';

export interface TripEntryProps {
  tripId: number;
  onSelectPress: (tripId: number) => void;
}

export function TripEntry({ tripId, onSelectPress }: TripEntryProps) {
  const {
    cart: { items: cartItems },
  } = useCart();
  const { tripOffer, totalPassengerCount, isLoading } = useTripOffer(tripId);
  const departureStationId = tripOffer?.departureStationId;
  const arrivalStationId = tripOffer?.arrivalStationId;
  const departureStation = useBusStation(departureStationId);
  const arrivalStation = useBusStation(arrivalStationId);

  if (isLoading || tripOffer === undefined) {
    return <>Loading offer...</>;
  }

  const { bookable, minPrice, currency, departureTime, arrivalTime } =
    tripOffer;
  const departureDate = new Date(departureTime);
  const arrivalDate = new Date(arrivalTime);

  const isInCart =
    cartItems?.find((cartItem) => cartItem.tripId === tripId) !== undefined;

  /* TODO: The <Box> component does not support `is-shady` nor `is-fullwidth` */
  return (
    <Box>
      {/* TODO: Should we first use the <Card> component */}
      <Card.Content paddingless>
        <Columns>
          {/* Bus stations and times */}
          <Columns.Column
            desktop={{ size: 'four-fifths' }}
            tablet={{ size: 'four-fifths' }}
            paddingless
          >
            <TripLine align="left">
              <TripLineStop
                time={formatHourMinutes(departureDate)}
                boldTime
                content={
                  <StationContent
                    stationName={departureStation.station?.name}
                    address={departureStation.station?.address}
                  />
                }
              />
              <TripLineStop
                time={formatHourMinutes(arrivalDate)}
                boldTime
                content={
                  <StationContent
                    stationName={arrivalStation.station?.name}
                    address={arrivalStation.station?.address}
                  />
                }
                outlined
                last
              />
            </TripLine>
          </Columns.Column>

          <Columns.Column
            desktop={{ size: 'one-fifth' }}
            tablet={{ size: 'one-fifth' }}
            display="flex"
            flexDirection="column"
            textAlign="center"
          >
            <Button
              type="button"
              color="primary"
              disabled={!bookable || isInCart}
              onClick={() => onSelectPress(tripId)}
            >
              {isInCart ? 'Wybrano' : 'Wybierz'}
            </Button>
            <Element textSize={5} textWeight="bold">
              {formatPrice(`${minPrice}`, currency)}
            </Element>
            <span className="has-text-grey">
              {formatCount(totalPassengerCount, 'pasażer', 'pasażerów')}
            </span>
          </Columns.Column>
        </Columns>
        {/* </div> */}
      </Card.Content>
    </Box>
  );
}
