import { useEffect, useMemo } from 'react';
import { QueryStatus, useQuery } from '@tanstack/react-query';
import { atom, useAtom } from 'jotai';
import { PassengersCounts } from '../../cart/api';
import { makePassengerCountsParams } from '../../trip_search/utils';
import { countTotalPassengers } from '../../trip_search/utils';

export interface TripOfferDTO {
  departureTime: string; // eg. "2023-08-21T11:30:00+02:00"
  departureStationId: number;
  departureStation: string; // eg. "Szczecin"
  arrivalTime: string; // eg. "2023-08-21T13:30:00+02:00"
  arrivalStationId: number;
  arrivalStation: string; // eg. "Berlin"
  minPrice: number; // eg. 60.00
  maxPrice: number; // eg. 50.00
  currency: 'PLN' | 'EUR';
  bookable: boolean; // eg. false
  numFreeSeats: number; // eg. 0
  passengersCounts: PassengersCounts;
  tripId: number; // eg. 123
}

export interface PassengerCountParams {
  adults: number;
  students: number;
  adolescents: number;
  children: number;
  infants: number;
  pets: number;
  packages: number;
}

export interface TripSearchInputs extends PassengerCountParams {
  tripType: 'single' | 'roundTrip';
  direction: 'there' | 'back';
  from: string;
  to: string;
  departureDate: Date;
  returnDate?: Date;
}

function toQueryParams(searchInputs: TripSearchInputs) {
  return Object.entries(searchInputs)
    .filter(([, v]) => v !== undefined)
    .map(([k, v]) => [k, v instanceof Date ? v.toISOString() : v])
    .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
    .join('&');
}

function fetchTripOffers(
  searchInputs: TripSearchInputs,
): Promise<TripOfferDTO[]> {
  // Swap the `from` and `to` directions for return trip
  if (searchInputs.direction === 'back') {
    searchInputs = {
      ...searchInputs,
      from: searchInputs.to,
      to: searchInputs.from,
    };
  }

  // Serialize passenger counts into query params
  const args = toQueryParams(searchInputs);
  const url = `https://berlineks.pasin.ski/api/trips?${args}`;

  // Send the query
  return fetch(url).then((resp) => resp.json());
}

const fetchTripOffersQueryStateAtom = atom<QueryStatus | undefined>(undefined);
const queriedTripSearchInputsAtom = atom<TripSearchInputs | undefined>(
  undefined,
);
const foundTripOffersAtom = atom<TripOfferDTO[] | undefined>(undefined);

export function useTripOffers(
  searchParams: TripSearchInputs,
  isValid: boolean,
) {
  const args = toQueryParams(searchParams);
  const [, setFetchTripOffersQueryState] = useAtom(
    fetchTripOffersQueryStateAtom,
  );
  const [, setQueriedTripSearchInputs] = useAtom(queriedTripSearchInputsAtom);
  const [, setFoundTripOffers] = useAtom(foundTripOffersAtom);
  const query = useQuery({
    queryKey: ['tripOffers', args],
    queryFn: () => fetchTripOffers(searchParams),
    enabled: isValid,
  });

  const { data, isLoading, isSuccess, status } = query;
  useEffect(() => {
    if (isValid) {
      setFetchTripOffersQueryState(status);

      if (!isLoading && isSuccess && data !== undefined) {
        setQueriedTripSearchInputs(searchParams);
        setFoundTripOffers(data);
      }
    }
  }, [isLoading, isSuccess, data, status]);

  return query;
}

export function useTripOffer(tripId: number) {
  const [fetchTripOffersQueryState] = useAtom(fetchTripOffersQueryStateAtom);
  const [queriedTripSearchInputs] = useAtom(queriedTripSearchInputsAtom);
  const [foundTripOffers] = useAtom(foundTripOffersAtom);

  const api = useMemo(() => {
    const passengerCountsParams = makePassengerCountsParams(
      (param) => queriedTripSearchInputs?.[param] ?? 0,
    );
    const totalPassengerCount = countTotalPassengers(passengerCountsParams);
    return {
      tripOffer: foundTripOffers?.find((offer) => offer.tripId === tripId),
      searchParams: queriedTripSearchInputs,
      passengerCountsParams,
      totalPassengerCount,
      isLoading: fetchTripOffersQueryState === 'loading',
    };
  }, [
    foundTripOffers,
    queriedTripSearchInputs,
    fetchTripOffersQueryState,
    tripId,
  ]);

  return api;
}
