import { Form as RouterForm } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Box,
  Container,
  Columns,
  Heading,
  Tile,
  Button,
} from 'react-bulma-components';
import { TextInput } from './components/TextInput';
import { Checkbox } from './components/Checkbox';
import './App.css';
import { CartListing } from './cart/CartListing';

interface ReservationInputs {
  firstName: string;
  lastName: string;
  email: string;
  cellphone: string;
  consent: boolean; // accepted "Terms of Service" and "Privacy policy"
}

const defaultReservationInputs: ReservationInputs = {
  firstName: '',
  lastName: '',
  email: '',
  cellphone: '',
  consent: false,
};

function CheckoutPage() {
  const { control, handleSubmit } = useForm<ReservationInputs>({
    defaultValues: defaultReservationInputs,
  });

  return (
    <RouterForm method="get" onSubmit={handleSubmit(() => {})}>
      <Container>
        <Columns>
          <Columns.Column tablet={{ size: 'two-thirds' }}>
            <Box>
              Czy posiadasz konto na Berlineks? (pozwoli to na automatycznie
              wypełnienie formularza i dodanie go do historii przejazdów)
            </Box>

            <Box>
              <Heading>Dane zamawiającego</Heading>
              <Columns multiline>
                {/* First column is for bus stations selection */}
                <Columns.Column
                  // widescreen={{ size: 'half' }}
                  tablet={{ size: /*'full'*/ 12 }}
                  // mobile={{ size: /*'full'*/12 }}
                >
                  <Columns>
                    <Columns.Column
                      tablet={{ size: 'half' }}
                      mobile={{ size: /*'full'*/ 12 }}
                    >
                      <TextInput
                        control={control}
                        name="firstName"
                        placeholder={'Imię'}
                      />
                    </Columns.Column>

                    <Columns.Column
                      tablet={{ size: 'half' }}
                      mobile={{ size: /*'full'*/ 12 }}
                    >
                      <TextInput
                        control={control}
                        name="lastName"
                        placeholder="Nazwisko"
                      />
                    </Columns.Column>
                  </Columns>
                </Columns.Column>
              </Columns>

              <Columns multiline>
                <Columns.Column
                  // widescreen={{ size: 'half' }}
                  tablet={{ size: /*'full'*/ 12 }}
                  // mobile={{ size: /*'full'*/12 }}
                >
                  <Columns>
                    <Columns.Column
                      tablet={{ size: 'half' }}
                      mobile={{ size: /*'full'*/ 12 }}
                    >
                      <TextInput
                        control={control}
                        name="email"
                        type="email"
                        placeholder={'Adres e-mail'}
                      />
                    </Columns.Column>

                    <Columns.Column
                      tablet={{ size: 'half' }}
                      mobile={{ size: /*'full'*/ 12 }}
                    >
                      <TextInput
                        control={control}
                        name="cellphone"
                        type="tel"
                        placeholder="Telefon komórkowy"
                      />
                    </Columns.Column>
                  </Columns>
                </Columns.Column>
              </Columns>

              <Checkbox
                control={control}
                name="consent"
                label="Przeczytałem i akceptuję regulamin i politykę prywatności"
              />
            </Box>

            <Box>
              <Heading>Płatność</Heading>
              Wybór metody płatności (Dla Przelewy24 Wyrazenie zgody na
              przetwarzanie przez Przelewy24 danych)
            </Box>
          </Columns.Column>

          {/* Sidebar */}
          <Columns.Column tablet={{ size: 'one-third' }}>
            <Tile kind="ancestor">
              <Tile size={12} kind="parent" vertical>
                <Tile kind="child">
                  <Box>
                    <CartListing withSummary />
                  </Box>
                  <Button submit>Zapłać</Button>
                </Tile>
              </Tile>
            </Tile>
          </Columns.Column>
        </Columns>
      </Container>
    </RouterForm>
  );
}

export default CheckoutPage;
