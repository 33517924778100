import { Button, Icon } from 'react-bulma-components';
import { Color, Size } from 'react-bulma-components/src/components';

interface BulmaButtonProps {
  color?: Color;
  size?: Size;
  state?: 'hover' | 'focus' | 'active';
  outlined?: boolean;
  inverted?: boolean;
  submit?: boolean;
  reset?: boolean;
  loading?: boolean;
  fullwidth?: boolean;
  disabled?: boolean;
  remove?: boolean;
  isSelected?: boolean;
  isStatic?: boolean;
  rounded?: boolean;
  text?: boolean;
}

// interface BulmaIconProps {
//   icon?: string;
//   size?: Size | 'auto';
//   align?: 'left' | 'right';
//   color?: Color;
//   text?: Boolean;
// }

interface IconButtonProps extends BulmaButtonProps {
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';

  icon?: string;
  iconSize?: Size | 'auto';
  iconAlign?: 'left' | 'right';
  iconColor?: Color;
}

export function IconButton({
  onClick,
  type = 'button',
  icon,
  iconSize,
  iconAlign,
  iconColor,
  ...buttonProps
}: IconButtonProps) {
  return (
    <Button type={type} onClick={onClick} {...buttonProps}>
      <Icon
        size={iconSize ?? buttonProps.size}
        align={iconAlign}
        color={iconColor}
      >
        <i className={icon} />
      </Icon>
    </Button>
  );
}
