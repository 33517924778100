export function zip<TypeA, TypeB>(a: TypeA[], b: TypeB[]): [TypeA, TypeB][] {
  return a.map((k, i) => [k, b[i]]);
}

export function addDays(n: number, from: Date | undefined = undefined): Date {
  const fromDate = from === undefined ? new Date() : from;
  const resultingDate = new Date(fromDate);
  resultingDate.setDate(fromDate.getDate() + n);
  return resultingDate;
}

export function tryParseDecimal(
  decimalString?: string | null,
  fallback?: number,
): number | undefined {
  if (typeof decimalString === 'string' && /^\d+$/.test(decimalString)) {
    return parseInt(decimalString, 10);
  } else {
    return fallback;
  }
}

// export function tryParseEnum<TField>(enumString: undefined|string|null, values: Set<TField>, fallback?: TField): TField|undefined {
//   if (typeof(enumString) === 'string' && values.has(enum))
// }

export function tryParseDate(
  dateString?: string | null,
  fallback?: Date,
): Date | undefined {
  const validDatePattern = /^\d{4}-\d{2}-\d{2}/;
  if (dateString === undefined || dateString === null) {
    return fallback; // The date is not even a string...
  } else if (validDatePattern.test(dateString)) {
    // The argument looks like a date
    // TODO: Try to check if it also has valid looking time part
    const datePart = dateString.slice(0, 10);
    try {
      return new Date(datePart);
    } catch {
      // Invalid date
      return fallback;
    }
  }
  return fallback;
}

export function formatCount(
  amount: number,
  singularText: string,
  pluralText: string,
): string {
  if (amount === 1) {
    return `${amount} ${singularText}`;
  } else {
    return `${amount} ${pluralText}`;
  }
}

export function formatPrice(amount: string, currency: 'EUR' | 'PLN'): string {
  if (currency === 'EUR') {
    return `€ ${amount}`;
  } else {
    return `${amount} zł`;
  }
}

export function formatHourMinutes(date: Date | string): string {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}
