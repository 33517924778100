import { useController, Control, FieldValues, Path } from 'react-hook-form';
import { Form } from 'react-bulma-components';
import { Color } from 'react-bulma-components/src/components';

export interface CheckboxProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
  color?: Color;
  error?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
}

export function Checkbox<TFieldValues extends FieldValues>({
  control,
  name,
  label,
  color,
  error,
  fullWidth = false,
  disabled = false,
  required = true, // HACK: This is inverted default!
}: CheckboxProps<TFieldValues>) {
  const {
    field: { ref, ...field },
    fieldState: { error: controllerError, invalid },
  } = useController({
    control,
    name,
    rules: {
      required: {
        value: required, // TODO: Should we short-circuit it if it's disabled?
        message: 'Pole nie moze być puste',
      },
      minLength: {
        value: 2,
        message: 'Pole musi zawierać conajmniej 2 litery',
      },
    },
  });
  const errorMsg = error || controllerError?.message;

  return (
    <Form.Field>
      <Form.Control fullwidth={fullWidth}>
        <Form.Checkbox
          color={invalid ? 'danger' : (color as string)}
          disabled={disabled}
          {...field}
          domRef={ref}
        >
          {label}
        </Form.Checkbox>
      </Form.Control>
      {errorMsg && <Form.Help color="danger">{errorMsg}</Form.Help>}
    </Form.Field>
  );
}
