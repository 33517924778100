import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useListQuery } from '../hooks';
import { fetchBusStation, fetchBusStations } from './api';

export function useBusStations() {
  const { data, isLoading, error } = useListQuery({
    queryKey: ['stations'],
    queryFn: () => fetchBusStations(),
    keyExtractor: (item) => item.id,
    queryKeyExtractor: (key) => ['stations', key],
  });

  return useMemo(
    () => ({
      stations: data,
      isLoading,
      error,
    }),
    [data, isLoading, error],
  );
}

export function useBusStation(id: number | undefined) {
  const { data, isLoading, error } = useQuery({
    queryKey: ['stations', id],
    queryFn: () => fetchBusStation(id as number),
    enabled: id !== undefined,
  });

  return useMemo(
    () => ({
      station: data,
      isLoading,
      error,
    }),
    [data, isLoading, error],
  );
}
