import { useCallback, useEffect, useMemo, useSyncExternalStore } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createEmptyCart,
  putItemToCart,
  Cart,
  CartItem,
  fetchCart,
  deleteItemFromCart,
} from './api';

function isSameCartItem(a: CartItem, b: CartItem) {
  if (a.id !== undefined && b.id !== undefined) {
    return a.id === b.id;
  } else {
    return a.tripId === b.tripId;
  }
}

function addCartItemLocally(state: Cart, cartItem: CartItem): Cart {
  if (cartItem.id !== undefined) {
    throw new Error('cartItem.id MUST be undefined');
  }

  const nextItems = [...(state.items ?? []), cartItem];
  return {
    ...state,
    items: nextItems,
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function updateCartItemLocally(state: Cart, cartItem: CartItem): Cart {
  if (cartItem.id === undefined) {
    // FIXME: We might update an element before it's sent to the server (so it won't have ID yet)!
    throw new Error('Valid cartItem.id MUST be provided');
    // return state;
  }

  const nextItems = state.items?.map((item) =>
    isSameCartItem(cartItem, item) ? cartItem : item,
  );
  return {
    ...state,
    items: nextItems,
  };
}

function removeCartItemLocally(state: Cart, cartItem: CartItem): Cart {
  // We might get a case where we will not have an ID (before we sync with server)
  const nextItems = state.items?.filter(
    (item) => !isSameCartItem(cartItem, item),
  );
  return {
    ...state,
    items: nextItems,
  };
}

const defaultEmptyCartState: Cart = {
  currency: 'PLN',
  paymentMethod: 'TRANSFER',
  amount: 0.0,
  items: [],
};

interface CartApi {
  addItem: (item: CartItem) => void;
  //hasItem: (itemId: number) => void;
  removeItem: (item: CartItem) => void;
  cart: Cart;
  // getAllItems: () => CartItem[];
}

interface CartMutationContext {
  previousItems?: Cart;
}

const subscribeToLocalStorage = (listener: (ev: StorageEvent) => void) => {
  window.addEventListener('storage', listener);
  return () => {
    window.removeEventListener('storage', listener);
  };
};

const getLocalStoreSnapshot = () => {
  return localStorage.getItem('cartUuid');
};

const setLocalStoreValue = (newValue: string | null) => {
  const key = 'cartUuid';
  if (typeof newValue === 'string') {
    localStorage.setItem(key, newValue);
  } else {
    localStorage.removeItem(key);
  }
};

function useLocalStorageString(): [
  string | null,
  (newValue: string | null) => void,
] {
  const value = useSyncExternalStore(
    subscribeToLocalStorage,
    getLocalStoreSnapshot,
  );
  return [value, setLocalStoreValue];
}

export function useCart(): CartApi {
  const [cartUuidRaw, setCartUuid] = useLocalStorageString();
  const cartUuid = cartUuidRaw ?? undefined;
  const queryClient = useQueryClient();

  const mutateAddItem = useCallback(
    (item: CartItem) => {
      if (cartUuid !== undefined) {
        return putItemToCart(cartUuid, item.tripId, item.passengersCounts);
      } else {
        return Promise.reject('Invalid cartUuid');
      }
    },
    [cartUuid, queryClient],
  );

  // const mutateUpdateItem = useCallback((item: CartItem) => {
  //   if (cartUuid !== undefined) {
  //     updateItemInCart(cartUuid, item);
  //   }
  // }, [cartUuid, queryClient]);

  const mutateRemoveItem = useCallback(
    (item: CartItem) => {
      if (cartUuid !== undefined && item.id !== undefined) {
        return deleteItemFromCart(cartUuid, item.id).then(() => {});
      } else {
        return Promise.reject('Invalid cartUuid or item.id');
      }
    },
    [cartUuid, queryClient],
  );

  const queryKey = ['cart'];
  const queryFn =
    cartUuid === undefined
      ? () => createEmptyCart(defaultEmptyCartState)
      : () => fetchCart(cartUuid);
  const { data, isLoading } = useQuery({
    queryKey,
    queryFn,
  });

  useEffect(() => {
    if (isLoading === false && typeof data?.uuid === 'string') {
      setCartUuid(data?.uuid);
    }
  }, [isLoading, data]);

  const addCartItem = useMutation<
    CartItem,
    unknown,
    CartItem,
    CartMutationContext
  >(mutateAddItem, {
    // mutationFn: mutateAddItem,
    onMutate: async (newItem: CartItem) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousItems = queryClient.getQueryData<Cart>(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData<Cart>(queryKey, (old) =>
        addCartItemLocally(old ?? defaultEmptyCartState, newItem),
      );

      // Return a context object with the snapshotted value
      return { previousItems } as CartMutationContext;
    },
    // If mutation fails
    onError: (err, newItem, context) => {
      queryClient.setQueryData<Cart>(queryKey, context?.previousItems);
    },
    // Always refetch after error or success
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  // const updateCartItem = useMutation({
  //   mutationFn: updateItemInCart(uuid, cartItem),
  //   onMutate: async (newTodo) => {
  //     await queryClient.cancelQueries({ queryKey: ['cart'] });

  //     const previousTodos = queryClient.getQueryData()
  //   },
  // });

  const removeCartItem = useMutation<
    void,
    unknown,
    CartItem,
    CartMutationContext
  >({
    mutationFn: mutateRemoveItem,
    onMutate: async (item: CartItem) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousItems = queryClient.getQueryData<Cart>(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData<Cart>(queryKey, (old) =>
        removeCartItemLocally(old ?? defaultEmptyCartState, item),
      );

      // Return a context object with the snapshotted value
      return { previousItems };
    },
    // If mutation fails
    onError: (err, item, context) => {
      queryClient.setQueryData<Cart>(queryKey, context?.previousItems);
    },
    // Always refetch after error or success
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const cartApi = useMemo<CartApi>(
    () => ({
      addItem: addCartItem.mutate,
      removeItem: removeCartItem.mutate,
      cart: data ?? defaultEmptyCartState,
    }),
    [addCartItem, removeCartItem, data],
  );

  return cartApi;
}
