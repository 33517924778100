import { useController, Control, FieldValues, Path } from 'react-hook-form';
import { Form } from 'react-bulma-components';
import { Color } from 'react-bulma-components/src/components';
import { tryParseDate } from '../utils';

export interface DatePickerProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  color?: Color;
  leftIcon?: string;
  error?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  defaultValue: Date;
  minValue?: Date;
}

export function DatePicker<TFieldValues extends FieldValues>({
  control,
  name,
  color,
  error,
  fullWidth = false,
  disabled = false,
  defaultValue,
  minValue,
}: DatePickerProps<TFieldValues>) {
  const {
    field: { ref, value, onChange, ...field },
    fieldState: { error: controllerError, invalid },
  } = useController({
    control,
    name,
    rules: {
      required: {
        value: true, // TODO: Should we short-circuit it if it's disabled?
        message: 'To pole nie moze być puste',
      },
      min: minValue && {
        value: minValue.toISOString()?.slice(0, 10),
        message: 'Nie mozna wybrać wcześniejszej daty',
      },
    },
  });

  const errorMsg = error || controllerError?.message;

  return (
    <>
      <Form.Control fullwidth={fullWidth}>
        {/* FIXME: Form.Input does not have `rounded` prop! */}
        <Form.Input
          type="date"
          color={invalid ? 'danger' : color}
          disabled={disabled}
          {...field}
          onChange={(e) => onChange(tryParseDate(e.target.value, defaultValue))}
          value={value.toISOString().slice(0, 10)}
          min={minValue?.toISOString()?.slice(0, 10)}
          domRef={ref}
        />
      </Form.Control>
      {errorMsg && <Form.Help color="danger">{errorMsg}</Form.Help>}
    </>
  );
}
