const API_SERVER_URL = 'https://berlineks.pasin.ski';
const STATION_API_URL = `${API_SERVER_URL}/api/stations`;

export interface BusStationDTO {
  name: string;
  lng: number;
  lat: number;
  address: string;
  postalCode: string;
  cityName: string;
  id: number;
}

export function fetchBusStations(): Promise<BusStationDTO[]> {
  return fetch(STATION_API_URL).then((res) => res.json());
}

export function fetchBusStation(id: number): Promise<BusStationDTO> {
  return fetch(`${STATION_API_URL}/${id}`).then((res) => res.json());
}
