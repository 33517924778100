import { PassengerCountParams } from '../api/trips/api';
import { PassengersCounts } from '../cart/api';

export const emptyPassengerCountParams: PassengerCountParams = {
  infants: 0,
  children: 0,
  adolescents: 0,
  students: 0,
  adults: 0,
  pets: 0,
  packages: 0,
};

export const defaultPassengerCountParams: PassengerCountParams = {
  ...emptyPassengerCountParams,
  adults: 1,
};

const passengerCountsMapping: Record<
  keyof PassengersCounts,
  keyof PassengerCountParams
> = {
  numInfants: 'infants',
  numChildren: 'children',
  numAdolescents: 'adolescents',
  numStudents: 'students',
  numAdults: 'adults',
  numPets: 'pets',
  numParcels: 'packages',
};

export function makePassengerCountsParams(
  getter: (param: keyof PassengerCountParams) => number | undefined,
  defaults: PassengerCountParams = emptyPassengerCountParams,
) {
  return Object.fromEntries(
    Object.values(passengerCountsMapping).map((param) => [
      param,
      getter(param) ?? defaults[param],
    ]),
  ) as unknown as PassengerCountParams;
}

export function makePartialPassengerCountsParams(
  getter: (param: keyof PassengerCountParams) => number | undefined,
  defaults?: Partial<PassengerCountParams>,
) {
  return Object.fromEntries(
    Object.values(passengerCountsMapping).map((param) => [
      param,
      getter(param) ?? defaults?.[param],
    ]),
  ) as unknown as Partial<PassengerCountParams>;
}

export function makePassengerCounts(
  getter: (
    field: keyof PassengersCounts,
    param: keyof PassengerCountParams,
  ) => number,
): PassengersCounts {
  return Object.fromEntries(
    Object.entries(passengerCountsMapping).map(([field, param]) => [
      field as keyof PassengersCounts,
      getter(field as keyof PassengersCounts, param),
    ]),
  ) as unknown as PassengersCounts;
}

export function toPassengerCounts(passengerCountsParams: PassengerCountParams) {
  return makePassengerCounts((_field, param) => passengerCountsParams[param]);
}

export function countTotalPassengers(counts: PassengerCountParams): number {
  return (
    Math.max(0, counts.adults) +
    Math.max(0, counts.students) +
    Math.max(0, counts.adolescents) +
    Math.max(0, counts.children) +
    Math.max(0, counts.infants)
  );
  // + Math.max(0, counts.pets) // TODO: Should we count pets as passengers?
  // + Math.max(0, counts.packages) // TODO: §hould we count packages as passengers?
}

export function groupBySections<TItem>(
  items: TItem[],
  keyExtractor: (item: TItem) => string,
): Record<string, TItem[]> {
  const groups = Object.create(null) as Record<string, TItem[]>;
  items.forEach((item) => {
    const key = keyExtractor(item);
    if (key in groups) {
      groups[key].push(item);
    } else {
      groups[key] = [item];
    }
  });
  return groups;
}
