const API_SERVER_URL = 'https://berlineks.pasin.ski';

type Currency = 'PLN' | 'EUR';

type PaymentMethod = 'CASH' | 'TRANSFER';

export interface PassengersCounts {
  /**
   * Positive number of babies upto 12 months of age.
   */
  numInfants: number;
  /**
   * Positive number of underaged passengers, between 1 and 6 years old.
   */
  numChildren: number;
  /**
   * Positive number of underaged passengers, between 6 and 18 years old.
   */
  numAdolescents: number;
  /**
   * Positive number of passengers which have a valid student card.
   */
  numStudents: number;
  /**
   * Positive number of passengers above 18 years old,
   * which are not eligable for any other discount.
   */
  numAdults: number;
  /**
   * Positive number of small and medium pets.
   */
  numPets: number;
  /**
   * Positive number of packages to deliver.
   */
  numParcels: number;
}

export interface CartItem {
  /**
   * The unique identifier of the trip that we are booking.
   */
  tripId: number;
  /**
   * Numbers of passengers that we are booking the trip for.
   * This is used to calculate the final price from.
   */
  passengersCounts: PassengersCounts;
  /**
   * The (estimated) price for this single trip.
   *
   * While this amount is initially calculated on client side,
   * only the value received from the backend is used as the
   * single source of truth and therefore might be overwritten.
   * This is also a safety mechanism to ignore forged payloads.
   */
  price: number;
  /**
   * Unique identifier assigned for this cart item.
   */
  id?: number;
}

export interface Cart {
  currency: Currency;
  paymentMethod: PaymentMethod;
  amount?: number;
  items?: CartItem[];
  uuid?: string;
}

export function createEmptyCart({
  currency = 'PLN',
  paymentMethod = 'TRANSFER',
}: Cart): Promise<Cart> {
  const requestBody = JSON.stringify({
    currency,
    paymentMethod,
  });

  return fetch(`${API_SERVER_URL}/api/carts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: requestBody,
  }).then((res) => res.json());
}

export function fetchCart(uuid: string): Promise<Cart> {
  return fetch(`${API_SERVER_URL}/api/carts/${uuid}`).then((res) => res.json());
}

export function putItemToCart(
  uuid: string,
  tripId: number,
  passengersCounts: PassengersCounts,
): Promise<CartItem> {
  const requestBody = JSON.stringify({
    tripId,
    passengersCounts,
  });

  return fetch(`${API_SERVER_URL}/api/carts/${uuid}/items`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: requestBody,
  }).then((res) => res.json());
}

export function updateItemInCart(
  uuid: string,
  cartItem: CartItem,
): Promise<CartItem> {
  if (cartItem.id === undefined) {
    throw new Error('cartItem.id must be valid');
  }
  return fetch(`${API_SERVER_URL}/api/carts/${uuid}/items/${cartItem.id}`).then(
    (res) => res.json(),
  );
}

export function deleteItemFromCart(
  uuid: string,
  itemId: number,
): Promise<Response> {
  return fetch(`${API_SERVER_URL}/api/carts/${uuid}/items/${itemId}`, {
    method: 'DELETE',
  });
}
