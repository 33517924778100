import { Element } from 'react-bulma-components'; // eslint-disable-line no-redeclare

export interface StationContentProps {
  stationName?: string;
  address?: string;
}

export function StationContent({ stationName, address }: StationContentProps) {
  return (
    <Element display="flex" flexDirection="column">
      <Element textSize={5} textWeight="bold">
        {stationName}
      </Element>
      <span>{address}</span>
    </Element>
  );
}
