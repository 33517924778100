import { useState } from 'react';
import { Element, Heading } from 'react-bulma-components';
import { useCart } from './hook';
import { useTripOffer } from '../api/trips/api';
import { useBusStation } from '../api/stations/hooks';
import { formatCount, formatHourMinutes, formatPrice } from '../utils';
import { TripLine, TripLineStop } from '../trip_search/TripLine';
import { IconButton } from '../components/IconButton';
import { PassengersCounts } from './api';

interface CartItemProps {
  tripId: number;
  passengersCounts: PassengersCounts;
  onRemovePress: () => void;
}

function countPassengers(passengersCounts: PassengersCounts): number {
  return (
    Math.max(0, passengersCounts.numInfants) +
    Math.max(0, passengersCounts.numChildren) +
    Math.max(0, passengersCounts.numAdolescents) +
    Math.max(0, passengersCounts.numStudents) +
    Math.max(0, passengersCounts.numAdults)
  );
}

function getPassengersCountsLabels(
  passengersCounts: PassengersCounts,
): string[] {
  const parts = [
    formatCount(passengersCounts.numAdults, 'dorosły', 'dorosłych'),
    formatCount(passengersCounts.numStudents, 'student', 'studentów'),
    formatCount(
      passengersCounts.numAdolescents,
      'dziecko (6-18 lat)',
      'dzieci (6-18 lat)',
    ),
    formatCount(
      passengersCounts.numChildren,
      'dziecko (do 6 lat)',
      'dzieci (do 6 lat)',
    ),
    formatCount(passengersCounts.numInfants, 'niemowlę', 'niemowlęta'),
  ];
  return parts.filter((text) => !text.startsWith('0'));
}

export function CartItem({
  tripId,
  passengersCounts,
  onRemovePress,
}: CartItemProps) {
  const [detailsUnfolded, setDetailsUnfolded] = useState(false);
  const { tripOffer, isLoading } = useTripOffer(tripId);
  const departureStation = useBusStation(tripOffer?.departureStationId);
  const arrivalStation = useBusStation(tripOffer?.arrivalStationId);
  const totalPassengers = countPassengers(passengersCounts);
  const passengersCountsLabels = getPassengersCountsLabels(passengersCounts);
  const passengersTooltip = passengersCountsLabels.join(', ');

  if (isLoading || tripOffer === undefined) {
    return null;
  }

  return (
    <Element key={tripId}>
      <Element display="flex">
        <TripLine align="left" paddingless>
          <TripLineStop
            content={
              <>
                <Element>
                  {formatHourMinutes(tripOffer.departureTime)}{' '}
                  {departureStation?.station?.name}
                </Element>
                <Element
                  textColor="grey-light"
                  title={passengersTooltip}
                  onClick={() => setDetailsUnfolded((old) => !old)}
                >
                  {formatCount(totalPassengers, 'pasażer', 'pasażerów')}
                  {detailsUnfolded ? ' ▼ ' : ' ▶ '}
                  {detailsUnfolded
                    ? passengersCountsLabels.map((label) => (
                        <Element key={label} textColor="grey-light">
                          {' ... '}
                          {label}
                        </Element>
                      ))
                    : undefined}
                </Element>
              </>
            }
            compact
          />
          <TripLineStop
            content={
              <>
                {formatHourMinutes(tripOffer.arrivalTime)}{' '}
                {arrivalStation?.station?.name}
              </>
            }
            compact
            outlined
            last
          />
        </TripLine>
        <Element display="flex" flexDirection="column" justifyContent="center">
          <IconButton
            onClick={onRemovePress}
            icon="fa fa-trash"
            color="danger"
            outlined
          />
        </Element>
      </Element>
      <hr />
    </Element>
  );
}

interface CartListingProps {
  withSummary?: boolean;
}

export function CartListing({ withSummary = false }: CartListingProps) {
  const { removeItem, cart } = useCart();
  const itemsCount = cart.items?.length ?? 0;

  const cartContent =
    itemsCount === 0 ? (
      <>Nie wybrano żadnych przejazdów</>
    ) : (
      cart?.items
        ?.sort((a, b) => a.tripId - b.tripId)
        ?.map((trip) => (
          <CartItem
            key={trip.id ?? trip.tripId}
            tripId={trip.tripId}
            passengersCounts={trip.passengersCounts}
            onRemovePress={() => removeItem(trip)}
          />
        ))
    );

  const cartSummary = withSummary ? (
    <Element textAlign="right">
      <b>Łącznie</b>{' '}
      {formatPrice(cart.amount?.toFixed(2) ?? '0.00', cart.currency)} <br />
    </Element>
  ) : undefined;

  return (
    <>
      {/* And current order status (like a cart), what trips have been added so far */}
      <Heading>
        {itemsCount === 1 ? 'Twoja rezerwacja' : 'Twoje rezerwacje'}
      </Heading>
      {cartContent}
      {itemsCount > 0 ? cartSummary : undefined}
    </>
  );
}
